import { CheckIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  HStack,
  IconButton,
  LinkBox,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CreatedByPanel, GammaTooltip } from '@gamma-app/ui'
import { format, parseISO } from 'date-fns'
import { memo, useCallback } from 'react'

import { config } from 'config'
import { GetThemesDocument, useDuplicateThemeMutation } from 'modules/api'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { Theme, ThemeType } from 'modules/theming'
import { ThemePreviewThumbnail } from 'modules/theming/components/ThemePreviewThumbnail'
import { useUserContext } from 'modules/user'

import { DeleteThemeModal } from './DeleteThemeModal'

type ThemePreviewProps = {
  theme: Theme
  isChecked?: boolean
  onThemeClicked?: (newTheme: Theme) => void
  onEditClick?: (themeToEdit?: Theme) => void
  variant?: 'outline' | 'ghost'
  type?: ThemeType
}

export const ThemePreview = memo(
  ({
    theme,
    onThemeClicked,
    isChecked = false,
    type = 'custom',
    onEditClick,
    variant = 'outline',
  }: ThemePreviewProps) => {
    const analytics = useAnalytics()
    const toast = useToast()
    const createdTime =
      theme.createdTime && format(parseISO(theme.createdTime), 'PPP')
    const isGhost = variant === 'ghost'

    const openThemeEditorWithThisTheme = useCallback(() => {
      onEditClick?.(theme)
    }, [onEditClick, theme])

    const {
      isOpen: isDeleteThemeModalOpen,
      onOpen: onDeleteThemeModalOpen,
      onClose: onDeleteThemeModalClose,
    } = useDisclosure({ id: 'deleteThemeModalDisclosure' })

    const { user, currentWorkspace, isGammaOrgUser } = useUserContext()
    const [duplicateTheme] = useDuplicateThemeMutation()

    const onDuplicate = useCallback(() => {
      if (!user || !currentWorkspace) return

      const { id, ...rest } = theme

      duplicateTheme({
        variables: { id },
        update: (cache, { data }) => {
          if (!data?.duplicateTheme) return

          // Write the newly created theme into the cache so it shows up in the
          // list without refreshing
          cache.writeQuery({
            query: GetThemesDocument,
            variables: {
              workspaceId: theme.workspaceId,
              archived: false,
            },
            data: {
              themes: [data.duplicateTheme],
            },
          })
        },
        optimisticResponse: {
          duplicateTheme: {
            id: 'temp',
            __typename: 'Theme',
            ...rest,
            name: `${theme.name} (copy)`,
            archived: false,
            createdTime: new Date().toISOString(),
            updatedTime: new Date().toISOString(),
          },
        },
      })
        .then(({ data }) => {
          if (!data) return
          analytics?.track(SegmentEvents.THEME_DUPLICATED, {
            theme_id: data.duplicateTheme.id,
            source_theme_id: id,
            name: theme.name,
          })
          analytics?.track(SegmentEvents.THEME_CREATED, {
            theme_id: data.duplicateTheme.id,
            name: data.duplicateTheme.name,
            type: 'duplicate',
          })
          toast({
            title: `Theme ${data.duplicateTheme.name} has been saved`,
            status: 'success',
            duration: 3000,
            position: 'top',
            isClosable: true,
          })
        })
        .catch((err) => {
          console.error(`Couldn't duplicate theme ${theme.name} error: ${err}`)
          toast({
            title: `Couldn't duplicate theme. ${err}`,
            status: 'error',
            duration: 3000,
            position: 'top',
            isClosable: false,
          })
        })
    }, [user, currentWorkspace, theme, duplicateTheme, analytics, toast])

    const renderCreatedBy = type === 'custom' && !!theme.createdBy?.displayName
    const themeName = (
      <HStack spacing={1} alignItems="flex-start">
        {isChecked && (
          <CheckIcon w={3} h="auto" mr={1} mt={1} color="trueblue.700" />
        )}
        <Text
          fontSize="sm"
          noOfLines={1}
          data-testid="theme-name"
          color={isChecked ? 'trueblue.700' : undefined}
        >
          {theme.name}
        </Text>
      </HStack>
    )

    return (
      <Box
        as={LinkBox}
        bgColor={isChecked ? 'trueblue.100' : isGhost ? undefined : 'linen.50'}
        textAlign="left"
        tabIndex={0}
        borderRadius="md"
        transitionProperty={isGhost ? 'common' : 'none'}
        transitionDuration="normal"
        _focus={{
          boxShadow: isChecked ? undefined : 'outline',
        }}
        outline="none"
        onClick={() => onThemeClicked?.(theme)}
        _hover={{
          shadow: isGhost || isChecked ? undefined : 'lg',
          bgColor: isChecked
            ? 'trueblue.100'
            : isGhost
            ? 'trueblue.50'
            : 'gray.100',
        }}
        shadow={isGhost ? undefined : 'md'}
        p={isGhost ? 2 : 0}
        data-theme-id={theme.id}
        data-testid={isChecked ? 'current-theme-preview' : undefined}
        cursor="pointer"
      >
        <ThemePreviewThumbnail theme={theme} />
        <Flex align="baseline" mt={1} direction="column" p={isGhost ? 0 : 2}>
          {renderCreatedBy && <Flex my={2}>{themeName}</Flex>}
          <HStack alignItems="flex-start" w="100%">
            {renderCreatedBy ? (
              <CreatedByPanel
                createdByYou={theme.createdBy?.id === user?.id}
                createdByName={theme.createdBy?.displayName!}
                createdByProfileImageUrl={theme.createdBy?.profileImageUrl}
                timestamp={theme.updatedTime}
                timestampDescriptor="Updated"
              />
            ) : (
              themeName
            )}
            <Spacer />
            {(theme.workspaceId ||
              (isGammaOrgUser &&
                config.APPLICATION_ENVIRONMENT !== 'production')) && (
              <Menu isLazy>
                <MenuButton
                  as={IconButton}
                  isRound
                  size="xs"
                  variant="ghost"
                  colorScheme="blackAlpha"
                  icon={<FontAwesomeIcon icon={regular('ellipsis')} />}
                  onClick={(ev) => ev.stopPropagation()} // Prevents clicking the ... from changing the theme
                >
                  Actions
                </MenuButton>

                <Portal>
                  <MenuList
                    zIndex="modal" // Necessary when this component is rendered inside a drawer
                    onClick={(ev) => ev.stopPropagation()} // Prevents clicking a menu item from changing the theme
                  >
                    <Box px={4} py={0} maxWidth="300px">
                      <Text noOfLines={3}>{theme.name}</Text>
                      <Text fontSize="sm" color="gray.500" noOfLines={1}>
                        Created {createdTime}
                      </Text>
                      {theme.createdBy && (
                        <GammaTooltip label={theme.createdBy.email}>
                          <Text
                            display="inline-block"
                            fontSize="sm"
                            color="gray.500"
                            noOfLines={1}
                          >
                            by {theme.createdBy.displayName}
                          </Text>
                        </GammaTooltip>
                      )}
                    </Box>
                    <MenuDivider />
                    <MenuItem
                      icon={
                        <FontAwesomeIcon icon={regular('palette')} fixedWidth />
                      }
                      onClick={openThemeEditorWithThisTheme}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      icon={
                        <FontAwesomeIcon icon={regular('clone')} fixedWidth />
                      }
                      onClick={onDuplicate}
                    >
                      Duplicate
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                      color="red.500"
                      icon={
                        <FontAwesomeIcon icon={regular('trash')} fixedWidth />
                      }
                      onClick={onDeleteThemeModalOpen}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </Portal>
                <DeleteThemeModal
                  theme={theme}
                  isOpen={isDeleteThemeModalOpen}
                  onClose={onDeleteThemeModalClose}
                />
              </Menu>
            )}
          </HStack>
        </Flex>
      </Box>
    )
  }
)

ThemePreview.displayName = 'ThemePreview'
